import { createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock($setup["NModal"], {
    class: "add-different-files-modal main-modal",
    preset: "dialog",
    "show-icon": false,
    title: $props.title || `Add ${$props.singleFile ? 'file' : 'files'}`
  }, {
    default: _withCtx(() => [
      _createVNode($setup["Dropzone"], {
        xlsxAllowed: $props.xlsxAllowed,
        docsAllowed: $props.docsAllowed,
        rfqListsAllowed: $props.rfqListsAllowed,
        threeDFilesAllowed: $props.threeDFilesAllowed,
        drawingsAllowed: $props.drawingsAllowed,
        singleFile: $props.singleFile,
        onFilesPicked: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('extraFilesPicked', $event)))
      }, null, 8, ["xlsxAllowed", "docsAllowed", "rfqListsAllowed", "threeDFilesAllowed", "drawingsAllowed", "singleFile"])
    ]),
    _: 1
  }, 8, ["title"]))
}